$(function () {
  const $form = $('#add-variant-form')
  const $link = $('.inward-entry-batch-number-create-btn')
  if ($form.length) {
    const $input = $form.find('#id_inward_entry_batch_number')
    const $inputList = $form.find('#id_inward_entry_batch_number_list')
    const $hiddenInput = $form.find('#id_inward_entry_batch_number-hidden')
    $input.on('change', function (e) {
      $inputList.find('option').each(function () {
        if ($(this).val() === e.target.value) {
          $hiddenInput.attr('value', $(this).attr('data-value'))
        }
      })
    })
    $input.on('keyup', function (e) {
      updateQueryParams($link, 'code', getReplaceSpecialChars(e.target.value))
      $.ajax({
        url:
          $input.attr('formaction') +
          '?term=' +
          e.target.value +
          '&_type=query&q=' +
          e.target.value,
        method: 'GET',
        complete: (response) => {
          // Write HTML if got 400 response, otherwise pretend nothing happened
          if (response.status === 400) {
            $inputList.empty()
            $inputList.append($('<option disabled/>').text('No Result Found'))
          }
        },
        success: (response) => {
          $inputList.empty()
          if (response.results && response.results.length > 0) {
            response.results.forEach((each) => {
              $inputList.append(
                $('<option/>')
                  .attr('data-value', each.id)
                  .attr('value', each.text)
                  .text(each.text)
              )
            })
          } else {
            $inputList.append($('<option disabled/>').text('No Result Found'))
          }
        },
      })
    })
    $form.find('select[name="variant"]').on('change', function () {
      updateQueryParams($link, 'variant_pk', this.value)
    })
  }
})

const getReplaceSpecialChars = function (string) {
  return string.replace('&', '').replace('?', '')
}

const updateQueryParams = function (element, key, value) {
  const urlArray = element.attr('data-href').split('?')
  if (urlArray.length === 1) {
    element.attr('data-href', urlArray[0] + '?' + key + '=' + value)
  } else if (urlArray.length > 1) {
    const queryParams = JSON.parse(
      '{"' +
        decodeURI(urlArray[1])
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    )
    queryParams[key] = value
    let queryParamString = ''
    Object.keys(queryParams).forEach((key, index) => {
      queryParamString =
        queryParamString + (index > 0 ? '&' : '') + key + '=' + queryParams[key]
    })
    element.attr('data-href', urlArray[0] + '?' + queryParamString)
  }
}
