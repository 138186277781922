import jsQR from 'jsqr'

$(function () {
  // Defining constants
  const successAudio = new Audio(
    'https://cdn.buyceps.com/audio/scan-success.mp3'
  )
  const errorAudio = new Audio('https://cdn.buyceps.com/audio/scan-failure.mp3')
  const errorColor = '#FF3B58'

  $('.scan-barcode-dialog-trigger').on('click', function (e) {
    // Variable initialize
    const $target = $(e.target)
    const $barcodeForm = $target.closest('form')
    const $dialogTrigger = $target.is('a') ? $target : $target.closest('a')
    let tickHandler
    let animationRequestId

    // Function defs
    function handleError(field, message) {
      errorAudio.play()
      field.addClass('error')
      field.text(message)
      setTimeout(function () {
        alert(message)
        animationRequestId = requestAnimationFrame(tickHandler)
        field.removeClass('error')
      }, 1000)
    }

    function handleSuccess(field, message) {
      successAudio.play()
      field.removeClass('error')
      field.text(message)
      setTimeout(function () {
        animationRequestId = requestAnimationFrame(tickHandler)
        field.removeClass('error')
      }, 1000)
    }

    $('#scan-barcode-dialog').modal({
      dismissible: true, // Modal can be dismissed by clicking outside of the modal
      opacity: 1, // Opacity of modal background
      inDuration: 300, // Transition in duration
      outDuration: 200, // Transition out duration
      onOpenStart: function (modal, trigger) {
        // Callback for Modal open. Modal and trigger parameters available.
        const video = document.createElement('video')
        const $canvasElement = $('#scan-barcode-canvas')
        const $outputMessage = $('.scan-barcode-output__outputMessage')
        const canvas = $canvasElement[0].getContext('2d')

        $('#scan-barcode-dialog')
          .find('.modal__title')
          .text(
            $dialogTrigger.data('title')
              ? $dialogTrigger.data('title')
              : 'Scan Barcode'
          )

        // Adding listener to form to prevent default form behaviour
        if ($dialogTrigger.data('close-on-scan') === undefined) {
          $barcodeForm.on('submit', function (e) {
            e.preventDefault()
            const $target = $(e.currentTarget)
            const $action = $target.attr('action')
            $.ajax({
              url: $action,
              method: 'POST',
              async: false,
              data: $target.serialize(),
              complete: (response) => {
                if (response.status === 400) {
                  handleError($outputMessage, response.status.message)
                }
              },
              success: (response) => {
                if (response.errors) {
                  const errorMsgs = []
                  for (const key in response.errors) {
                    if (key in response.errors) {
                      errorMsgs.push(response.errors[key])
                    }
                  }
                  const errorMsg = errorMsgs.join('\n')
                  handleError($outputMessage, errorMsg)
                } else if (response.success) {
                  handleSuccess($outputMessage, response.success)
                } else {
                  handleSuccess($outputMessage, 'Processed')
                }
              },
            })
          })
        }

        function drawLine(begin, end, color) {
          canvas.beginPath()
          canvas.moveTo(begin.x, begin.y)
          canvas.lineTo(end.x, end.y)
          canvas.lineWidth = 4
          canvas.strokeStyle = color
          canvas.stroke()
        }

        // Use facingMode: environment to attempt to get the front camera on phones
        if (navigator.mediaDevices) {
          navigator.mediaDevices
            .getUserMedia({ video: { facingMode: 'environment' } })
            .then(function (stream) {
              video.srcObject = stream
              video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
              video.play()
              animationRequestId = requestAnimationFrame(tickHandler)
            })
        }

        tickHandler = function tick() {
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            $canvasElement.attr('hidden', false)
            $canvasElement.attr('height', video.videoHeight)
            $canvasElement.attr('width', video.videoWidth)
            canvas.drawImage(
              video,
              0,
              0,
              $canvasElement.attr('width'),
              $canvasElement.attr('height')
            )
            const imageData = canvas.getImageData(
              0,
              0,
              $canvasElement.attr('width'),
              $canvasElement.attr('height')
            )
            const code = jsQR(
              imageData.data,
              imageData.width,
              imageData.height,
              {
                inversionAttempts: 'dontInvert',
              }
            )
            if (code) {
              drawLine(
                code.location.topLeftCorner,
                code.location.topRightCorner,
                errorColor
              )
              drawLine(
                code.location.topRightCorner,
                code.location.bottomRightCorner,
                errorColor
              )
              drawLine(
                code.location.bottomRightCorner,
                code.location.bottomLeftCorner,
                errorColor
              )
              drawLine(
                code.location.bottomLeftCorner,
                code.location.topLeftCorner,
                errorColor
              )
              $outputMessage.text('Loading…')
              window.cancelAnimationFrame(animationRequestId)
              animationRequestId = undefined
              $barcodeForm
                .find('.scan-barcode-container')
                .find('input')
                .val(code.data)
              $barcodeForm.submit()
            } else {
              $outputMessage.text('Searching Barcode or QR')
            }
          }
          if (animationRequestId) {
            requestAnimationFrame(tickHandler)
          }
        }
      },
      onCloseEnd: function () {
        window.location.reload()
        $barcodeForm.off('submit')
      }, // Callback for Modal close
    })
  })
})
