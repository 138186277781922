function onPageInit() {
  $('#id_batch').on('select2:select', onSelectBatch)
  $('#id_unit_price_net_0').on('input', function () {
    const priceNet = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const priceGross = priceNet * (1 + tax)
    $('#id_unit_price_gross_0').val(priceGross.toFixed(2))

    const quantity = parseInt($('input#id_quantity')[0].value)
    const totalNet = priceNet * quantity
    $('#id_total_net_0').val(totalNet.toFixed(2))
    const totalGross = priceGross * quantity
    $('#id_total_gross_0').val(totalGross.toFixed(2))

    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const invoiceTotalNet = priceNet * invoiceQuantity
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))
    const invoiceTotalGross = priceGross * invoiceQuantity
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))
  })
  $('#id_unit_price_gross_0').on('input', function () {
    const priceGross = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const priceNet = priceGross / (1 + tax)
    $('#id_unit_price_net_0').val(priceNet.toFixed(2))

    const quantity = parseInt($('input#id_quantity')[0].value)
    const totalNet = priceNet * quantity
    $('#id_total_net_0').val(totalNet.toFixed(2))
    const totalGross = priceGross * quantity
    $('#id_total_gross_0').val(totalGross.toFixed(2))

    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const invoiceTotalNet = priceNet * invoiceQuantity
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))
    const invoiceTotalGross = priceGross * invoiceQuantity
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))
  })
  $('#id_quantity').on('input', function () {
    const priceGross = $('#id_unit_price_gross_0')[0].value
    const priceNet = $('#id_unit_price_net_0')[0].value
    const quantity = parseInt($(this).val())
    const totalNet = priceNet * quantity
    $('#id_total_net_0').val(totalNet.toFixed(2))
    const totalGross = priceGross * quantity
    $('#id_total_gross_0').val(totalGross.toFixed(2))
  })
  $('#id_invoice_quantity').on('input', function () {
    const priceGross = $('#id_unit_price_gross_0')[0].value
    const priceNet = $('#id_unit_price_net_0')[0].value
    const invoiceQuantity = parseInt($(this).val())
    const invoiceTotalNet = priceNet * invoiceQuantity
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))
    const invoiceTotalGross = priceGross * invoiceQuantity
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))
  })
  $('#id_total_net_0').on('input', function () {
    const totalNet = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const quantity = parseInt($('input#id_quantity')[0].value)
    const priceNet = totalNet / quantity
    $('#id_unit_price_net_0').val(priceNet.toFixed(2))
    const priceGross = priceNet * (1 + tax)
    $('#id_unit_price_gross_0').val(priceGross.toFixed(2))
    const totalGross = totalNet * (1 + tax)
    $('#id_total_gross_0').val(totalGross.toFixed(2))

    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const invoiceTotalNet = priceNet * invoiceQuantity
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))
    const invoiceTotalGross = priceGross * invoiceQuantity
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))
  })
  $('#id_total_gross_0').on('input', function () {
    const totalGross = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const quantity = parseInt($('input#id_quantity')[0].value)
    const priceGross = totalGross / quantity
    $('#id_unit_price_gross_0').val(priceGross.toFixed(2))
    const priceNet = priceGross / (1 + tax)
    $('#id_unit_price_net_0').val(priceNet.toFixed(2))
    const totalNet = totalGross / (1 + tax)
    $('#id_total_net_0').val(totalNet.toFixed(2))

    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const invoiceTotalNet = priceNet * invoiceQuantity
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))
    const invoiceTotalGross = priceGross * invoiceQuantity
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))
  })
  $('#id_invoice_total_net_0').on('input', function () {
    const invoiceTotalNet = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const priceNet = invoiceTotalNet / invoiceQuantity
    $('#id_unit_price_net_0').val(priceNet.toFixed(2))
    const priceGross = priceNet * (1 + tax)
    $('#id_unit_price_gross_0').val(priceGross.toFixed(2))
    const invoiceTotalGross = invoiceTotalNet * (1 + tax)
    $('#id_invoice_total_gross_0').val(invoiceTotalGross.toFixed(2))

    const quantity = parseInt($('input#id_quantity')[0].value)
    const totalNet = priceNet * quantity
    $('#id_total_net_0').val(totalNet.toFixed(2))
    const totalGross = priceGross * quantity
    $('#id_total_gross_0').val(totalGross.toFixed(2))
  })
  $('#id_invoice_total_gross_0').on('input', function () {
    const invoiceTotalGross = parseFloat($(this).val())
    const tax = parseFloat($('#id_product_tax').html().replace('%', '')) / 100
    const invoiceQuantity = parseInt($('input#id_invoice_quantity')[0].value)
    const priceGross = invoiceTotalGross / invoiceQuantity
    $('#id_unit_price_gross_0').val(priceGross.toFixed(2))
    const priceNet = priceGross / (1 + tax)
    $('#id_unit_price_net_0').val(priceNet.toFixed(2))
    const invoiceTotalNet = invoiceTotalGross / (1 + tax)
    $('#id_invoice_total_net_0').val(invoiceTotalNet.toFixed(2))

    const quantity = parseInt($('input#id_quantity')[0].value)
    const totalNet = priceNet * quantity
    $('#id_total_net_0').val(totalNet.toFixed(2))
    const totalGross = priceGross * quantity
    $('#id_total_gross_0').val(totalGross.toFixed(2))
  })
}

function onSelectBatch(e) {
  const data = e.params.data
  $('#id_product_tax').html(parseFloat(data.tax).toFixed(2) + '%')
  $('#id_variant').html(data.variant)
  $('#id_mfg_date').html(data.mfg_date)
  $('#id_exp_date').html(data.exp_date)
  $('#id_upc').html(data.upc)
  // $('#id_mrp_0').val(data.mrp);
  if ('unit_price_net' in data) {
    $('#id_unit_price_net_0').val(data.unit_price_net)
    $('#id_unit_price_gross_0').val(data.unit_price_gross)
    $('#id_total_net_0').val(data.total_net)
    $('#id_total_gross_0').val(data.total_gross)
    $('#id_invoice_quantity').val(data.invoice_quantity)
    $('#id_invoice_total_net_0').val(data.invoice_total_net)
    $('#id_invoice_total_gross_0').val(data.invoice_total_gross)
  }
}

// -----
onPageInit()
