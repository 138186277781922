import { initSelects } from '../components/selects.js'

const onAsyncFormSubmit = (e) => {
  e.preventDefault()
  const $target = $(e.currentTarget)
  let $action = $target.attr('action')
  const $submitButton = $target.find('button[type=submit][clicked=true]')
  const $formAction = $submitButton.attr('formaction')
  if (typeof $formAction !== typeof undefined && $formAction !== false) {
    $action = $formAction
  }
  $.ajax({
    url: $action,
    method: 'POST',
    data: $target.serialize(),
    complete: (response) => {
      // Write HTML if got 400 response, otherwise pretend nothing happened
      if (response.status === 400) {
        $target.parent().html(response.responseText)
        initSelects()
      } else {
        $('.modal-close').click()
      }
    },
    success: (response) => {
      const $form = $('#add-variant-form')
      if ($form.length) {
        const $input = $form.find('#id_inward_entry_batch_number')
        const $inputList = $form.find('#id_inward_entry_batch_number_list')
        const $hiddenInput = $form.find('#id_inward_entry_batch_number-hidden')
        if (response.success) {
          $input.attr('value', response.batch.code)
          $inputList.append(
            $('<option/>')
              .attr('value', response.batch.id)
              .text(response.batch.code)
          )
          $hiddenInput.attr('value', response.batch.id)
        }
      }
    },
  })
}

const onAsyncFormButtonClick = (e) => {
  const $button = $(e.currentTarget)
  const $formAsync = $button.parents('.inward_entry_batch_create_modal')
  $('button[type=submit]', $formAsync).removeAttr('clicked')
  $button.attr('clicked', 'true')
}

const onModalClose = () => $('.modal').modal('close')

// -----

$(document)
  .on(
    'click',
    '.inward_entry_batch_create_modal button[type=submit]',
    onAsyncFormButtonClick
  )
  .on('submit', '.inward_entry_batch_create_modal', onAsyncFormSubmit)
  .on('click', '.modal-close', onModalClose)
