import { svgInjector } from './misc.js'
import { initSelects } from './selects.js'

// List of input types that can be autofocused after opening modal
const focusInputs = [
  'textarea',
  'input[type="text"]:not(.select-dropdown)',
  'input[type="number"]',
]

// -----

$('body').on('click', '.modal-trigger-custom', (e) => {
  const target = e.currentTarget
  e.preventDefault()
  $.ajax({
    url: target.dataset.href,
    method: 'GET',
    success: (response) => {
      const $modal = $(target.getAttribute('href'))
      $modal.html(response).modal('open')
      initSelects()
      const inputs = $modal.find(focusInputs.join(','))

      // Automatically focus first autofocusable input in modal after opening
      if (inputs.length) {
        $modal.find(focusInputs.join(','))[0].focus()
      }

      // Inject all SVGs from modal
      svgInjector.inject(
        document.querySelectorAll(
          '.modal-content svg[data-src]:not(.injected-svg)'
        )
      )
    },
  })
})

$('body').on('submit', '.modal-trigger-custom-form-error', (e) => {
  e.preventDefault()
  const target = e.currentTarget
  $.ajax({
    url: target.action,
    method: 'POST',
    data: $(e.currentTarget).serialize(),
    headers: {
      'X-CSRFToken': $.cookie('csrftoken'),
    },
    success: (response) => {
      const $modal = $('#base-modal')
      $modal.html(response).modal('open')
    },
  })
})

$('body').on('submit', '.modal-trigger-custom-form-post', (e) => {
  e.preventDefault()
  const target = e.currentTarget
  $.ajax({
    url: target.dataset.action,
    method: 'POST',
    data: $(e.currentTarget).serialize(),
    headers: {
      'X-CSRFToken': $.cookie('csrftoken'),
    },
    success: (response) => {
      const $modal = $(target.getAttribute('action'))
      $modal.html(response).modal('open')
    },
  })
})
