$(function () {
  $('.datepicker').datepicker({
    autoClose: true,
    showClearBtn: true,
    format: 'yyyy-mm-dd',
    i18n: {
      // Months and weekdays
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthsShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      // Materialize modified
      weekdaysAbbrev: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      clear: 'Clear',
      close: 'Close',
    },
    onClose: () => $(document.activeElement).blur(),
  })
})

$('.datepicker').on('mousedown', function (event) {
  event.preventDefault()
})
