function updateProgress(target, reload) {
  fetch(target.dataset.progressUrl).then(function (response) {
    response.json().then(function (data) {
      if (data.status === 'completed' || data.status === 'error') {
        location.reload()
      }
      if (data.status === 'created') {
        setTimeout(updateProgress, 5000, target, true)
        return
      }
      if (reload) {
        location.reload()
      }
      let progress = data.progress
      if (progress > 2) {
        progress = progress - 2
      }
      $(target.firstElementChild).css('width', `${progress}%`)
      setTimeout(updateProgress, 500, target, false)
    })
  })
}

function updateNumericProgress(target, reload) {
  fetch(target.dataset.progressUrl).then(function (response) {
    response.json().then(function (data) {
      if (data.status === 'completed' || data.status === 'error') {
        location.reload()
      }
      if (data.status === 'created') {
        setTimeout(updateNumericProgress, 5000, target, true)
        return
      }
      if (reload) {
        location.reload()
      }
      const progress = data.progress
      target.innerHTML = `${progress}%`
      setTimeout(updateNumericProgress, 500, target, false)
    })
  })
}

$(function () {
  $.each($('.progress-bar'), function (key, value) {
    updateProgress(value)
  })

  $.each($('.progress-bar-numeric'), function (key, value) {
    updateNumericProgress(value)
  })
})
